<footer class="footer">
  <ul class="footer__link-list">
    <li class="footer__link-list__item">
      <button
        (click)="showModal(FOOTER_MODAL.PRIVACY)"
        class="footer__link-list__link"
      >
        {{ DATA.privacyLink.text }}
      </button>
    </li>
    <li class="footer__link-list__item">
      <button
        (click)="showModal(FOOTER_MODAL.TERMS)"
        class="footer__link-list__link"
      >
        {{ DATA.termsLink.text }}
      </button>
    </li>
  </ul>
  <img src="assets/images/logo-ici.png" alt="Ici logo" class="footer__logo" />
  <p>{{ DATA.copyrightText }}</p>
</footer>
