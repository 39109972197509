import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FormDataService } from '../services/form-data.service';
import { AppUrls } from '../enums/app-urls.enum';

export const planSelectionGuard: CanActivateFn = (route, state) => {
  const formService = inject(FormDataService);
  const router = inject(Router);
  const formDataExists = Object.keys(formService.formData).length > 0;
  if (formDataExists) {
    return true;
  }
  router.navigate([AppUrls.HOME]);
  return false;
};
