export const STATIC_DATA = {
  phoneLink: {
    url: '800-576-2674',
    icon: 'assets/icons/phone.svg',
  },

  emailLink: {
    url: 'support@tripinsuranceconsultants.com',
    icon: 'assets/icons/email.svg',
  },

  logoLink: {
    url: '',
    icon: 'assets/icons/logo.svg',
  },
};
