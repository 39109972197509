import { Component } from '@angular/core';

@Component({
  selector: 'app-service-modal',
  standalone: true,
  imports: [],
  templateUrl: './service-modal.component.html',
  styleUrl: './service-modal.component.scss'
})
export class ServiceModalComponent {

}
