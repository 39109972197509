import { Component } from '@angular/core';
import {
  RouterLink,
} from '@angular/router';
import { STATIC_DATA } from './data/static-data';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  public readonly DATA = STATIC_DATA;

  get emailLink() {
    return `mailto:${this.DATA.emailLink.url}`;
  }

  get phoneLink() {
    return `tel:${this.DATA.phoneLink.url}`;
  }
}
