export const STATIC_DATA = {
  copyrightText: '© 2024 Insurance Consultants International',

  privacyLink: {
    text: 'Privacy',
    link: '',
  },

  termsLink: {
    text: 'Terms',
    link: '',
  },
};
