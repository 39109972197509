<div class="modal-content">
  <div class="section">
    <h2 class="section__header">Your Privacy Matters to Us</h2>
    <p>
      At Insurance Consultants International, we take your privacy seriously.
      The information you provide through our TripWise Travel Protection Plan
      application is used solely to process your application, manage your
      policy, and provide you with the services you've requested. We are
      committed to safeguarding your personal data and ensuring that your
      information is handled securely.
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">Information We Collect</h2>
    <p>
      We collect personal information that you voluntarily provide when you
      apply for the TripWise Travel Protection Plan. This may include your name,
      contact information, travel details, and payment information.
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">How We Use Your Information</h2>
    <p>The information we collect is used to:</p>
    <ul>
      <li>
        Process your application and provide the travel protection plan
        services.
      </li>
      <li>Communicate with you regarding your policy and any updates.</li>
      <li>Improve our services and user experience.</li>
      <li>Comply with legal obligations and protect our rights.</li>
    </ul>
  </div>

  <div class="section">
    <h2 class="section__header">Data Security</h2>
    <p>
      We implement a variety of security measures to maintain the safety of your
      personal information. Your data is stored on secure servers, and we use
      encryption and other technologies to protect it from unauthorized access.
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">Sharing Your Information</h2>
    <p>
      We do not sell or rent your personal information to third parties. We may
      share your data with trusted partners who assist us in operating our
      website, conducting our business, or servicing you, so long as those
      parties agree to keep your information confidential. We may also share
      your information when required by law or to protect our rights.
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">Your Rights</h2>
    <p>
      You have the right to access, correct, or delete the personal information
      we hold about you. If you wish to make any changes or have any concerns
      about your privacy, please contact us at
      <a href="mailto:support@tripinsuranceconsultants.com">
        support&#64;tripinsuranceconsultants.com
      </a>
      .
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">Changes to This Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. Any changes will be
      posted on this page, and the date of the last update will be indicated at
      the top. Your continued use of our services after any changes constitutes
      your acceptance of the new policy.
    </p>
  </div>

  <div class="section">
    <h2 class="section__header">Contact Us</h2>
    <p>
      If you have any questions or concerns regarding our Privacy Policy, please
      contact us at:
    </p>

    <div>
      <p>Insurance Consultants International</p>
      <p>Niceville, FL</p>
      <a href="tel:18005762674">1-800-576-2674</a>
      <a href="mailto:support@tripinsuranceconsultants.com ">
        support&#64;tripinsuranceconsultants.com
      </a>
    </div>
  </div>
</div>
