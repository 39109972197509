import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { PrivacyModalComponent } from '@shared/core/modules/privacy-modal/privacy-modal.component';
import { ServiceModalComponent } from '@shared/core/modules/service-modal/service-modal.component';
import { STATIC_DATA } from './data/static-data';

enum FooterModal {
  PRIVACY,
  TERMS,
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  providers: [DialogService],
})
export class FooterComponent {
  public DATA = STATIC_DATA;

  private readonly dialogService = inject(DialogService);

  public readonly FOOTER_MODAL = FooterModal;

  private readonly modalData = {
    0: PrivacyModalComponent,
    1: ServiceModalComponent,
  };

  showModal(option: FooterModal) {
    const prepareData = {
      header: option === FooterModal.PRIVACY ? 'Privacy Policy' : 'Terms of Service',
      modal: true,
      breakpoints: {
        '600px': '90vw',
      },
      style: { width: '452px' },
      dismissableMask: true,
    };

    this.dialogService.open(
      option === FooterModal.PRIVACY ? this.modalData[0] : this.modalData[1],
      prepareData,
    );
  }
}
