import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-modal',
  standalone: true,
  imports: [],
  templateUrl: './privacy-modal.component.html',
  styleUrl: './privacy-modal.component.scss'
})
export class PrivacyModalComponent {

}
