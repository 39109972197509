<div class="modal-content">
  <div class="section">
    <h2 class="section__header">Welcome to TripWise Travel Protection Plan</h2>
    <p>
      These Terms of Service ("Terms") govern your use of the TripWise Travel
      Protection Plan application and related services provided by Insurance
      Consultants International. By accessing or using our services, you agree
      to be bound by these Terms. If you do not agree with these Terms, please
      do not use our services.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Eligibility</h2>
    <p>
      To apply for the TripWise Travel Protection Plan, you must be at least 18
      years old and have the legal capacity to enter into a binding agreement.
      By submitting an application, you represent that you meet these
      eligibility requirements.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Application Process</h2>
    <p>
      When you apply for the TripWise Travel Protection Plan, you agree to
      provide accurate and complete information. Inaccurate or incomplete
      information may result in the denial of coverage or cancellation of your
      policy. It is your responsibility to keep your contact and travel
      information up to date.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Coverage and Limitations</h2>
    <p>
      The TripWise Travel Protection Plan offers specific coverage benefits as
      outlined in your policy documentation. It is important that you carefully
      review the coverage, exclusions, and limitations associated with your
      policy. Coverage is subject to the terms, conditions, and exclusions
      outlined in the policy.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Payment Terms</h2>
    <p>
      The cost of the TripWise Travel Protection Plan will be clearly
      communicated to you during the application process. By submitting your
      application, you agree to pay the required premium. Coverage is not in
      effect until payment is received and your application is approved.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Cancellation and Refunds</h2>
    <p>
      You may cancel your TripWise Travel Protection Plan within a specified
      period for a full refund, provided no claims have been made and you have
      not commenced your trip. After this period, cancellation may result in
      partial or no refund, depending on the terms of your policy.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Limitation of Liability</h2>
    <p>
      Insurance Consultants International shall not be liable for any direct,
      indirect, incidental, special, or consequential damages arising from your
      use of the TripWise Travel Protection Plan or related services, except as
      required by applicable law. Our total liability under these Terms shall
      not exceed the amount you paid for the TripWise Travel Protection Plan.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Changes to the Terms</h2>
    <p>
      We reserve the right to modify these Terms at any time. Any changes will
      be effective immediately upon posting on our website. Your continued use
      of our services after any changes constitutes your acceptance of the
      revised Terms.
    </p>
  </div>
  <div class="section">
    <h2 class="section__header">Contact Information</h2>
    <p>
      If you have any questions or concerns regarding these Terms, please
      contact us at:
    </p>
    <div>
      <p>Insurance Consultants International</p>
      <p>Niceville, FL</p>
      <a href="tel:18005762674">1-800-576-2674</a>
      <a href="mailto:support@tripinsuranceconsultants.com ">
        support&#64;tripinsuranceconsultants.com
      </a>
    </div>
  </div>
</div>
