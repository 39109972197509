import { Routes } from '@angular/router';
import { travelProtectionRoutes } from '@shared/core/routes/travel-protection.routes';
import { AppUrls } from './shared/core/enums/app-urls.enum';

export const routes: Routes = [
  {
    path: AppUrls.HOME,
    loadComponent: () => import('@pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: AppUrls.TRAVEL_PROTECTION,
    loadComponent: () => import('@pages/travel-protection/travel-protection.component').then(
      (m) => m.TravelProtectionComponent
    ),
    children: travelProtectionRoutes,
  },
];
