<nav class="navigation">
  <a routerLink="/">
    <object
      type="image/svg+xml"
      data="assets/icons/logo.svg"
      class="navigation__logo"
    ></object>
  </a>
  <ul class="navigation__link-list">
    <li class="navigation__link-list__item">
      <a
        href=""
        class="navigation__link-list__link"
        [href]="emailLink"
        target="_blank"
      >
        <img [src]="DATA.emailLink.icon" alt="" class="image" />
        <span class="link">{{ DATA.emailLink.url }}</span>
      </a>
    </li>
    <li class="navigation__link-list__item">
      <a [href]="phoneLink" class="navigation__link-list__link">
        <img [src]="DATA.phoneLink.icon" alt="" class="image" />
        <span class="link">{{ DATA.phoneLink.url }}</span>
      </a>
    </li>
  </ul>
</nav>
